<template>
  <div class="snackbar" :class="{show: snack}">{{message}}</div>
</template>

<script>
export default {
  name: 'Snackbar',
  data: function(){
    return{
      snack: false,
      message: ''
    }
  },
  methods: {
    show: function(msg, timeout){
      this.snack = true;
      this.message = msg;
      setTimeout(() => { this.snack = false; }, timeout ?? 4000);
    }
  }
}
</script>

<style lang="scss">

.snackbar {
    visibility: hidden;
    opacity: 0;
    min-width: 250px;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 12px;
    position: fixed;
    z-index: 10002;
    left: 50%;
    bottom: 0px;
    font-size: 16px;

    transition: all 0.5s ease;

    &.show {
      bottom: 55px;
      opacity: 1;
      transition: opacity 0.5s ease, bottom 0.5s ease;
      visibility: visible;
      // -webkit-animation: fadein 0.5s, fadeout 0.5s 2s;
      // animation: fadein 0.5s, fadeout 0.5s 2s;
    }
  }  

  .dark .snackbar {
    background-color: #1b1b1b;
  }

  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

</style>
